import moment from "moment/moment";
import ApiServices from "../api/services";
import Drawer from "../components/Drawer";
import { mediaUrl } from "../utils/config";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import React, { useEffect, useState } from "react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer, Bar, BarChart } from "recharts";

// months array
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Analytics = () => {
  // useState
  const todayEndDate = moment.utc().endOf("day");
  const todayStartDate = moment.utc().startOf("day");
  const [allNursries, setAllNursries] = useState([]);
  const [allCampuses, setAllCampuses] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [allSettingsList, setAllSettingsList] = useState([]);
  const [barChartLoading, setBarChartLoading] = useState(false);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState("today");
  const [employeeReasonsData, setEmployeeReasonsData] = useState(null);
  const [selectedCampusOption, setSelectedCampusOption] = useState(null);
  const [employeeAnalyticsData, setEmployeeAnalyticsData] = useState(null);
  const [selectedSettingOption, setSelectedSettingOption] = useState(null);
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null);
  const [openUserDrawerModal, setOpenUserDrawerModal] = useState({ open: false, data: null });
  // const [dateRangeForm, setDateRangeForm] = useState({ startDate: todayStartDate.unix(), endDate: todayEndDate.unix() });
  // const [employeeAnalyticDateRange, setEmployeeAnalyticDateRange] = useState({ startDate: todayStartDate.unix(), endDate: todayEndDate.unix() });
  const [dateError, setDateError] = useState("");

  const [selectedFilter, setSelectedFilter] = useState("today");
  const [selectedCampus, setSelectedCampus] = useState({ label: "All", value: "All" });
  const [selectedSetting, setSelectedSetting] = useState({ label: "All", value: "All" });
  const [selectedDepartment, setSelectedDepartment] = useState({ label: "All", value: "All" });

  const getCurrentDate = () => {
    const todayStart = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 0, 0, 0));
    const todayEnd = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 23, 59, 59));

    return { start: todayStart, end: todayEnd };
  };

  const today = getCurrentDate();

  const [dateRange, setDateRange] = useState({
    startDate: Math.floor(today.start.getTime() / 1000),
    endDate: Math.floor(today.end.getTime() / 1000),
  });

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].name;
      const value = payload[0].value;

      let color;

      const data2Item = data2.find((item) => item.name === name);

      return (
        <div className="relative rounded-20 bg-white p-4 shadow-card">
          <div className="h-6 w-6 rounded-full ">
            <img
              src={
                name === "Poor Sleep "
                  ? images.SleepIcon
                  : name === "Home Life Difficulties"
                    ? images.HomeLifeIcon
                    : name === "Relationship Problems"
                      ? images.RelationIcon
                      : name === "Unwell"
                        ? images.UnWellIcon
                        : name === "Bereavement"
                          ? images.BereavementIcon
                          : name === "Work Related Stress"
                            ? images.StressIcon
                            : name === "Mental Health Rest Day"
                              ? images.MentleHealth
                              : images.placeholder
              }
              alt="reason"
              className="h-full w-full rounded-full object-contain"
            />
          </div>
          <p className="label" style={{ color }}>{`${name} : ${value}%`}</p>
        </div>
      );
    }
    return null;
  };

  // employees reasons data handling
  const Unwell = employeeReasonsData ? parseFloat(employeeReasonsData?.unwell_count_percentage) : 0;
  const poorSleep = employeeReasonsData ? parseFloat(employeeReasonsData?.poor_sleep_count_percentage) : 0;
  const bereavement = employeeReasonsData ? parseFloat(employeeReasonsData?.bereavement_count_percentage) : 0;
  const mentleHealthAbsense = employeeReasonsData ? parseFloat(employeeReasonsData?.mars_absence_count_percentage) : 0;
  const workRelatedStres = employeeReasonsData ? parseFloat(employeeReasonsData?.work_related_stress_count_percentage) : 0;
  const relationshipProblems = employeeReasonsData ? parseFloat(employeeReasonsData?.relationship_problem_count_percentage) : 0;
  const homeLifeDifficulties = employeeReasonsData ? parseFloat(employeeReasonsData?.home_life_diffuculties_count_percentage) : 0;

  const data2 = [
    {
      name: "Poor Sleep ",
      value:
        poorSleep !== 0
          ? poorSleep
          : poorSleep == 0 &&
              Unwell == 0 &&
              relationshipProblems == 0 &&
              workRelatedStres == 0 &&
              homeLifeDifficulties == 0 &&
              bereavement == 0 &&
              mentleHealthAbsense == 0
            ? 100
            : 0,
      color: poorSleep !== 0 ? "#264254" : "#d1d5db",
    },
    {
      name: "Unwell",
      value: Unwell !== 0 ? Unwell : 0,
      color: Unwell !== 0 ? "#FEDEBC" : "#d1d5db",
    },
    {
      name: "Relationship Problems",
      value: relationshipProblems !== 0 ? relationshipProblems : 0,
      color: relationshipProblems !== 0 ? "#FCEBB0" : "#d1d5db",
    },
    {
      name: "Work Related Stress",
      value: workRelatedStres !== 0 ? workRelatedStres : 0,
      color: workRelatedStres !== 0 ? "#F0D2C9" : "#d1d5db",
    },
    {
      name: "Home Life Difficulties",
      value: homeLifeDifficulties !== 0 ? homeLifeDifficulties : 0,
      color: homeLifeDifficulties !== 0 ? "#FFBF9D" : "#d1d5db",
    },
    {
      name: "Bereavement",
      value: bereavement !== 0 ? bereavement : 0,
      color: bereavement !== 0 ? "#B6E7CF" : "#d1d5db",
    },
    {
      name: "Mental Health Rest Day",
      value: mentleHealthAbsense !== 0 ? mentleHealthAbsense : 0,
      color: mentleHealthAbsense !== 0 ? "#e8c7fd" : "#d1d5db",
    },
  ];

  // Individual employees analytics data handling
  const categories = [
    { id: "Great", classNameColor: "text-[#FFAE9B]", label: "Great", logo: images.GreatIcon, count: employeeReasonsData?.great_count },
    { id: "Okay", classNameColor: "text-[#FFBC96]", label: "Okay", logo: images.OkayIcon, count: employeeReasonsData?.okay_count },
    { id: "Meh", classNameColor: "text-[#FFDCB8]", label: "Meh", logo: images.MehIcon, count: employeeReasonsData?.meh_count },
    { id: "Low", classNameColor: "text-[#AAE9CD]", label: "Low", logo: images.LowIcon, count: employeeReasonsData?.low_count },
    {
      id: "Struggling",
      classNameColor: "text-[#264254]",
      label: "Struggling",
      logo: images.StugglingIcon,
      count: employeeReasonsData?.struggling_count,
    },
  ];

  const getFormattedDataline = (selectedValue, employeeAnalyticsData, startDate, endDate, activeCategory, isFilterApplied) => {
    let dataline = [];
    let xAxisLabels = [];

    const getDateString = (date) => moment(date).format("MM/DD/YYYY");

    switch (selectedValue) {
      case "today":
      case "yesterday":
        xAxisLabels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        dataline = xAxisLabels.map((hour) => ({
          name: hour,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const hourIndex = date.hour();
            if (dataline[hourIndex]) {
              const response = data.response;
              dataline[hourIndex][response] += 1;
            }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const hourIndex = date.hour();
            if (dataline[hourIndex]) {
              const response = data.response;
              dataline[hourIndex][response] += 1;
            }
          });
        }
        break;

      case "lastMonth":
        const startOfLastMonth = moment().subtract(1, "month").startOf("month");
        const endOfLastMonth = moment().subtract(1, "month").endOf("month");

        xAxisLabels = Array.from({ length: endOfLastMonth.date() }, (_, i) => `Day ${i + 1}`);
        dataline = xAxisLabels.map((day) => ({
          name: day,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);

          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            // if (date.isBetween(startOfLastMonth, endOfLastMonth, 'day', '[]')) {
            const dayIndex = date.date() - 1;
            if (dataline[dayIndex]) {
              const response = data.response;
              dataline[dayIndex][response] += 1;
            }
            // }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            // if (date.isBetween(startOfLastMonth, endOfLastMonth, 'day', '[]')) {
            const dayIndex = date.date() - 1;
            if (dataline[dayIndex]) {
              const response = data.response;
              dataline[dayIndex][response] += 1;
            }
            // }
          });
        }
        break;
      case "thisYear":
      case "lastYear":
        xAxisLabels = Array.from({ length: 12 }, (_, i) => moment().utc().month(i).format("MMM"));
        dataline = xAxisLabels.map((month) => ({
          name: month,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const targetYear = selectedValue === "thisYear" ? moment().utc().year() : moment().utc().subtract(1, "year").year();
            if (date.year() === targetYear) {
              const monthIndex = date.month();
              if (dataline[monthIndex]) {
                const response = data.response;
                dataline[monthIndex][response] += 1;
              }
            }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const targetYear = selectedValue === "thisYear" ? moment().utc().year() : moment().utc().subtract(1, "year").year();
            if (date.year() === targetYear) {
              const monthIndex = date.month();
              if (dataline[monthIndex]) {
                const response = data.response;
                dataline[monthIndex][response] += 1;
              }
            }
          });
        }
        break;

      case "lastSevenDays":
        const end = moment().utc();
        const start = moment().utc().subtract(7, "days");

        xAxisLabels = Array.from({ length: 8 }, (_, i) => getDateString(start.clone().add(i, "days")));
        dataline = xAxisLabels.map((day) => ({
          name: day,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const dateString = getDateString(date);
            const index = xAxisLabels.indexOf(dateString);
            if (index !== -1) {
              const response = data.response;
              dataline[index][response] += 1;
            }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const dateString = getDateString(date);
            const index = xAxisLabels.indexOf(dateString);
            if (index !== -1) {
              const response = data.response;
              dataline[index][response] += 1;
            }
          });
        }
        break;

      case "custom":
        if (startDate && endDate) {
          const startMoment = moment.unix(startDate).utc();
          const endMoment = moment.unix(endDate).utc();

          if (startMoment.isSame(endMoment, "day")) {
            // Render hourly data if the start and end dates are the same day
            xAxisLabels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
            dataline = xAxisLabels.map((hour) => ({
              name: hour,
              Great: 0,
              Okay: 0,
              Meh: 0,
              Low: 0,
              Struggling: 0,
            }));
            if (activeCategory) {
              const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
              filteredData?.forEach((data) => {
                const date = moment.unix(data.created_date_unix);
                if (date.isSame(startMoment, "day")) {
                  const hourIndex = date.hour();
                  if (dataline[hourIndex]) {
                    const response = data.response;
                    dataline[hourIndex][response] += 1;
                  }
                }
              });
            } else {
              employeeAnalyticsData?.forEach((data) => {
                const date = moment.unix(data.created_date_unix);
                if (date.isSame(startMoment, "day")) {
                  const hourIndex = date.hour();
                  if (dataline[hourIndex]) {
                    const response = data.response;
                    dataline[hourIndex][response] += 1;
                  }
                }
              });
            }
          } else {
            const diff = endMoment.diff(startMoment, "days");

            if (diff >= 0) {
              xAxisLabels = Array.from({ length: diff + 1 }, (_, i) => getDateString(startMoment.clone().add(i, "days")));
              dataline = xAxisLabels.map((label) => ({
                name: label,
                Great: 0,
                Okay: 0,
                Meh: 0,
                Low: 0,
                Struggling: 0,
              }));
              if (activeCategory) {
                const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
                filteredData?.forEach((data) => {
                  const date = moment.unix(data.created_date_unix);
                  const dateString = getDateString(date);
                  const index = xAxisLabels.indexOf(dateString);
                  if (index !== -1) {
                    const response = data.response;
                    dataline[index][response] += 1;
                  }
                });
              } else {
                employeeAnalyticsData?.forEach((data) => {
                  const date = moment.unix(data.created_date_unix);
                  const dateString = getDateString(date);
                  const index = xAxisLabels.indexOf(dateString);
                  if (index !== -1) {
                    const response = data.response;
                    dataline[index][response] += 1;
                  }
                });
              }
            }
          }
        }
        break;

      default:
        console.error("Invalid selectedValue:", selectedValue);
        break;
    }

    return { dataline, xAxisLabels };
  };

  const { dataline, xAxisLabels } = getFormattedDataline(
    selectedValue,
    employeeAnalyticsData,
    dateRange?.startDate,
    dateRange?.endDate,
    activeCategory,
    isFilterApplied,
  );

  const formatYAxis = (value) => `${value}`;
  const formatTooltip = (value) => {
    return value;
  };

  // handle employee analytical categories
  const handleCategoryClick = (label) => {
    if (activeCategory === label) {
      setActiveCategory("");
    } else {
      setActiveCategory(label);
    }
  };

  // get employee stats by id
  const getEmployeeStatsDashboard = (sDate, eDate, sCampus, sSetting, sDepartment) => {
    setBarChartLoading(true);
    let payload = {
      start_date: sDate !== null ? sDate : sDate,
      end_date: eDate !== null ? eDate : eDate,
      department_id: sDepartment?.value === "All" ? "" : sDepartment?.value,
      campus_id: sCampus?.value === "All" ? "" : sCampus?.value,
      nursery_id: sSetting?.value === "All" ? "" : sSetting?.value,
    };

    ApiServices.analytics
      .getEmployeeDashboardStats(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setBarChartLoading(false);
          setEmployeeReasonsData(data?.data);
          setEmployeeAnalyticsData(data?.data?.users_response);
        }
        setBarChartLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBarChartLoading(false);
      });
  };

  const getDataByFilter = async (filters) => {
    const filterCount = Object.values(filters).reduce((count, filter) => {
      if (filter !== undefined) {
        if (typeof filter === "object" && filter !== null) {
          const innerCount = Object.values(filter).reduce((innerCount, innerFilter) => (innerFilter !== undefined ? innerCount + 1 : innerCount), 0);
          return count + (innerCount > 0 ? 1 : 0);
        } else {
          return count + 1;
        }
      }
      return count;
    }, 0);

    setAppliedFiltersCount(filterCount);
  };

  const getSettingsWithoutPagination = () => {
    ApiServices.supportTickets
      .getSettingsWithoutPagination()
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          console.log("data====>", data);
          let settingsData = data?.data?.map((item) => ({
            image: item?.logo,
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ image: "", label: "All", value: "All" }, ...settingsData];

          setAllNursries(settingsData);
        }
      })
      .catch((err) => {});
  };

  const getCampusList = (nurseryValue) => {
    let payload = {
      mode: 1,
      nursery_id: nurseryValue,
    };
    ApiServices.analytics
      .getCampuses(payload)
      .then((res) => {
        const { data, message } = res;
        console.log({ data });
        if (data.code === 200) {
          let settingsData = data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ label: "All", value: "All" }, ...settingsData];

          setAllCampuses(settingsData);
        }
      })
      .catch((err) => {});
  };

  const getDepartmentList = (campusValue, nurseryValue) => {
    let payload = {
      mode: 1,
      campus_id: campusValue,
      nursery_id: nurseryValue ? nurseryValue : selectedSettingOption?.value,
    };

    ApiServices.analytics
      .getDepartments(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          console.log({ res });
          let settingsData = data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ label: "All", value: "All" }, ...settingsData];

          setAllDepartments(settingsData);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSettingsWithoutPagination();
    getCampusList();
    getDepartmentList();
    // getEmployeeStatsDashboard();
  }, []);

  // useEffect(() => {
  //   if (selectedSettingOption?.value && selectedSettingOption?.value !== "All") {
  //     getCampusList();
  //   } else {
  //     setAllCampuses([]);
  //     setSelectedCampusOption(null);
  //   }
  // }, [selectedSettingOption]);

  // useEffect(() => {
  //   if (selectedSettingOption?.value && selectedSettingOption?.value !== "All") {
  //     getDepartmentList();
  //   } else {
  //     setAllDepartments([]);
  //     setSelectedDepartmentOption(null);
  //   }
  // }, [selectedSettingOption]);

  useEffect(() => {
    // Trigger the API call when filters are cleared
    if (selectedSettingOption === null && selectedCampusOption === null && selectedDepartmentOption === null && appliedFiltersCount === 0) {
      getEmployeeStatsDashboard(dateRange.startDate, dateRange.endDate, selectedCampusOption, selectedSettingOption, selectedDepartmentOption);
    }
  }, [selectedSettingOption, selectedCampusOption, selectedDepartmentOption, appliedFiltersCount]);

  const clearFilter = () => {
    getCampusList();
    getDepartmentList();
    setSelectedFilter("today");
    setSelectedSetting({ label: "All", value: "All" });
    setSelectedCampus({ label: "All", value: "All" });
    setSelectedDepartment({ label: "All", value: "All" });
    setSelectedCampusOption(null);
    setSelectedDepartmentOption(null);
    setSelectedSettingOption(null);
    setAppliedFiltersCount(0);
    setAllDepartments([]);
    setAllCampuses([]);
    setDateRange({
      startDate: Math.floor(today.start.getTime() / 1000),
      endDate: Math.floor(today.end.getTime() / 1000),
    });
    setSelectedValue("today");
    setDateError("");
    setIsFilterApplied(false);
  };

  return (
    <div className="relative grid min-h-[83vh] grid-cols-12 gap-4">
      <div className="card col-span-12 space-y-8 ">
        <div className="mx-6 flex flex-row items-center justify-between border-b-[1px] border-b-grayColor200 pb-5">
          <div className="flex flex-wrap items-center gap-5 self-start pt-1.5">
            <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
              <img
                src={
                  selectedSettingOption && selectedSettingOption?.label !== "All" ? mediaUrl + selectedSettingOption?.image : images.AllSettingIcon
                }
                alt="filter icon"
                className={selectedSettingOption ? "h-5 w-5 rounded-full" : ""}
              />
              <h2 className="text-sm font-medium text-secondary">
                {selectedSettingOption ? (selectedSettingOption?.label === "All" ? "All Settings" : selectedSettingOption?.label) : "All Settings"}
              </h2>
            </div>
            <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
              <img src={images.AllCampusIcon} alt="filter icon" />
              <h2 className="text-sm font-medium text-secondary">
                {selectedCampusOption ? (selectedCampusOption?.label === "All" ? "All Campuses" : selectedCampusOption?.label) : "All Campuses"}
              </h2>
            </div>
            <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
              <img src={images.AllDepartmentIcon} alt="filter icon" />
              <h2 className="text-sm font-medium text-secondary">
                {selectedDepartmentOption
                  ? selectedDepartmentOption?.label === "All"
                    ? "All Departments"
                    : selectedDepartmentOption?.label
                  : "All Departments"}
              </h2>
            </div>
            <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
              <img src={images.CalenderIcon} alt="filter icon" />
              <h2 className="text-sm font-medium text-secondary">
                {selectedValue === "today"
                  ? `Today (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                  : selectedValue === "yesterday"
                    ? `Yesterday (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                    : selectedValue === "lastSevenDays"
                      ? `Last 7 days (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")} - ${moment.unix(dateRange?.endDate).utc().format("MM/DD/YYYY")})`
                      : selectedValue === "lastMonth"
                        ? `Last month (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                        : selectedValue === "thisYear"
                          ? `This year (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                          : selectedValue === "lastYear"
                            ? `Last year (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                            : selectedValue === "custom"
                              ? `(${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")} - ${moment.unix(dateRange?.endDate).utc().format("MM/DD/YYYY")})`
                              : `Today (${moment().utc().format("MM/DD/YYYY")})`}
              </h2>
            </div>
          </div>
          <div className="shrink-0 self-start">
            {appliedFiltersCount > 0 ? (
              <button
                type="button"
                className="font-poppins flex h-9 w-56 items-center justify-start
                   gap-x-3 rounded-full border  border-lightGreenColor px-4 text-base  font-medium  focus:outline-none"
              >
                <h2
                  className="shrink-0 text-base font-medium text-lightGreenColor"
                  onClick={() => setOpenUserDrawerModal({ open: true, data: null })}
                >
                  {appliedFiltersCount} active filters
                </h2>
                <div className="h-3/4 border-l-2 border-grayColor200"></div>
                <div className="flex items-center gap-x-1">
                  <img src={images.REDCLOSE} alt="Close" className="h-4 w-4" />
                  <h2 className="text-sm text-red-500" onClick={clearFilter}>
                    Clear
                  </h2>
                </div>
              </button>
            ) : (
              <button
                onClick={() => setOpenUserDrawerModal({ open: true, data: null })}
                type="button"
                className="font-poppins inline-flex h-9 w-40 items-center justify-start
                gap-x-5 rounded-full border border-transparent bg-lightGreenColor px-4 text-base  font-medium text-white hover:bg-lightGreenColor/75 focus:outline-none"
              >
                <AdjustmentsHorizontalIcon className="mr-2 h-5 w-5 text-white" /> Filter
              </button>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between px-6">
          <div className="w-full space-y-2">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-secondary-color">
                {employeeReasonsData?.healthy_percentage} <span className="font-medium">Settled</span>{" "}
              </h2>
              <h2 className="text-xl font-semibold text-secondary-color">
                {employeeReasonsData?.unhealthy_percentage} <span className="font-medium">Unsettled</span>{" "}
              </h2>
            </div>
            <div className="w-full">
              <div className="relative mb-4 flex h-10 overflow-hidden rounded-full bg-[#F5F5F5] text-xs">
                <div
                  className={`${employeeReasonsData?.healthy_percentage == "100%" ? "" : "clip-healthy"} `}
                  style={{ width: employeeReasonsData?.healthy_percentage, backgroundColor: "#8EE1C8" }}
                ></div>
                <div
                  className={` ${employeeReasonsData?.unhealthy_percentage == "100%" ? "" : "clip-unhealthy"} `}
                  style={{ width: employeeReasonsData?.unhealthy_percentage, backgroundColor: "#FF968D" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-3"></div>
        </div>
        <div className="grid grid-cols-12 gap-5 px-6 xl:divide-x">
          <div className="col-span-12 space-y-8 xl:col-span-7">
            <div className="flex items-center gap-x-4">
              <div className="relative ">
                <Heading heading="Employee Wellness Analytics" />
              </div>
            </div>
            <div className="space-y-8">
              {barChartLoading ? (
                <div className="grid grid-cols-5 gap-x-4">
                  <Skeleton count={1} height={150} borderRadius={16} />
                  <Skeleton count={1} height={150} borderRadius={16} />
                  <Skeleton count={1} height={150} borderRadius={16} />
                  <Skeleton count={1} height={150} borderRadius={16} />
                  <Skeleton count={1} height={150} borderRadius={16} />
                </div>
              ) : (
                <>
                  <div className="-ml-8 grid grid-cols-5 items-center">
                    {categories.map((category) => (
                      <div className="relative" key={category.id} onClick={() => handleCategoryClick(category.label)}>
                        <div
                          className={`flex cursor-pointer flex-col items-center gap-y-2 ${activeCategory && activeCategory !== category.label ? "opacity-50" : ""}`}
                        >
                          <img src={category?.logo} alt="logo" className="h-12 w-12" />
                          <h2 className="text-2xl font-medium text-secondary">{category?.count}</h2>
                          <h2 className={`${category?.classNameColor} text-sm font-medium uppercase 2xl:text-base`}>{category?.label}</h2>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {barChartLoading ? (
                <div className="grid grid-cols-1 gap-4">
                  {[...Array(1)].map((_, index) => (
                    <Skeleton key={index} count={1} height={350} borderRadius={16} />
                  ))}
                </div>
              ) : (
                <>
                  <div className="-ml-10">
                    <ResponsiveContainer width="100%" height={420}>
                      <BarChart
                        width={500}
                        height={300}
                        data={dataline}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={30}
                        barGap={5}
                      >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="name" tickLine={false} />
                        <YAxis tickLine={false} tickFormatter={formatYAxis} />
                        <Tooltip formatter={formatTooltip} />

                        <Bar dataKey="Great" fill="#FFAE9B" radius={[12, 12, 0, 0]} />
                        <Bar dataKey="Okay" fill="#FFBC96" radius={[12, 12, 0, 0]} />
                        <Bar dataKey="Meh" fill="#FFDCB8" radius={[12, 12, 0, 0]} />
                        <Bar dataKey="Low" fill="#AAE9CD" radius={[12, 12, 0, 0]} />
                        <Bar dataKey="Struggling" fill="#264254" radius={[12, 12, 0, 0]} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-span-12 xl:col-span-5">
            <div className="relative flex items-center justify-between pl-6 2xl:pl-12">
              <div className="relative">
                <Heading heading="Reasons" subheading="Meh, Low, Struggling" />
              </div>
            </div>
            {barChartLoading ? (
              <div className="px-12 pt-4">
                <Skeleton count={1} height={250} borderRadius={16} />
              </div>
            ) : (
              <>
                <div className="flex flex-col items-center gap-y-3 ">
                  <div>
                    <ResponsiveContainer width={260} height={320}>
                      <PieChart
                        margin={{
                          top: 5,
                          right: 5,
                          bottom: 5,
                          left: 0,
                        }}
                      >
                        <Pie
                          margin={{
                            top: 5,
                            right: 5,
                            bottom: 5,
                            left: 0,
                          }}
                          data={data2}
                          height={220}
                          width={300}
                          cx="50%"
                          cy="50%"
                          outerRadius={120}
                          fill="#d6d6d6"
                          dataKey="value"
                        >
                          {data2?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} stroke="none" className="border-none outline-none" />
                          ))}
                        </Pie>

                        {(poorSleep !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (Unwell !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (relationshipProblems !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (workRelatedStres !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (homeLifeDifficulties !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (bereavement !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          (mentleHealthAbsense !== 0 && <Tooltip content={<CustomTooltip />} />)}
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </>
            )}
            {barChartLoading ? (
              <div className="px-12 pt-4">
                <Skeleton count={1} height={250} borderRadius={16} />
              </div>
            ) : (
              <>
                <div className="space-y-6 pl-12">
                  <div className="grid w-full grid-cols-12 items-center justify-between gap-x-1 ">
                    <div className="col-span-6 ">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.SleepIcon} alt="poor sleep" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.poor_sleep_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Poor Sleep</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.HomeLifeIcon} alt="Home Life Difficulties" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.home_life_diffuculties_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Home Life Difficulties</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                    <div className="col-span-6 ">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.RelationIcon} alt="Relationship Problems" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.relationship_problem_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Relationship Problems</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.UnWellIcon} alt="Unwell" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.unwell_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Unwell</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                    <div className="col-span-6 ">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.BereavementIcon} alt="Bereavement" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.bereavement_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Bereavement</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.StressIcon} alt="Work Related Stress" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.work_related_stress_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Work Related Stress</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                    <div className="col-span-12 ">
                      <div className="flex items-center gap-x-1">
                        <div>
                          <img src={images.MentleHealth} alt="Mental Health Rest Day" className="h-10 w-10" />
                        </div>
                        <div>
                          <h2 className="font-bold text-secondary ">{employeeReasonsData?.mars_absence_count_percentage}</h2>
                          <p className="text-sm font-medium text-grayColor300">Mental Health Rest Day</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Drawer */}
      <Drawer
        isOpen={openUserDrawerModal.open}
        setIsOpen={(open) => setOpenUserDrawerModal((prev) => ({ ...prev, open }))}
        onApplyFilters={getDataByFilter}
        allSettingsList={allNursries}
        allCampuses={allCampuses}
        allDepartments={allDepartments}
        setDateRange={setDateRange}
        dateRange={dateRange}
        setSelectedDepartmentOption={setSelectedDepartmentOption}
        selectedDepartmentOption={selectedDepartmentOption}
        setSelectedSettingOption={setSelectedSettingOption}
        selectedSettingOption={selectedSettingOption}
        setSelectedCampusOption={setSelectedCampusOption}
        selectedCampusOption={selectedCampusOption}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        api={getEmployeeStatsDashboard}
        clearFilter={clearFilter}
        setIsFilterApplied={setIsFilterApplied}
        setDateError={setDateError}
        dateError={dateError}
        isFilterApplied={isFilterApplied}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedCampus={selectedCampus}
        setSelectedCampus={setSelectedCampus}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedSetting={selectedSetting}
        setSelectedSetting={setSelectedSetting}
        getDepartmentList={getDepartmentList}
        getCampusList={getCampusList}
        setAllDepartments={setAllDepartments}
        setAllCampuses={setAllCampuses}
      />
    </div>
  );
};

export default Analytics;
