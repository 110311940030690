// production
export const baseUrl = "https://api.edufeel.co.uk/";
export const mediaUrl = "https://api.edufeel.co.uk/images/nurseries/";
export const ticketMediaUrl = "https://api.edufeel.co.uk/images/ticket";
export const ticketResponseMediaUrl = "https://api.edufeel.co.uk/images/ticket_response";
export const userMediaUrl = "https://api.edufeel.co.uk/images/employee/";

// export const baseUrl = "https://api.edufeel.co.uk/";
// export const mediaUrl = "https://api.edufeel.co.uk/images/nurseries/";
// export const ticketMediaUrl = "https://api.edufeel.co.uk/images/ticket";
// export const ticketResponseMediaUrl = "https://api.edufeel.co.uk/images/ticket_response";
// export const userMediaUrl = "https://api.edufeel.co.uk/images/employee/";




// Dev
// export const baseUrl = "https://api.edufeel.dextrouslab.com/";
// export const mediaUrl = "https://api.edufeel.dextrouslab.com/images/nurseries/";
// export const ticketMediaUrl = "https://api.edufeel.dextrouslab.com/images/ticket";
// export const ticketResponseMediaUrl = "https://api.edufeel.dextrouslab.com/images/ticket_response";
// export const userMediaUrl = "https://api.edufeel.dextrouslab.com/images/employee/";
