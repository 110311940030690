import React, { useState } from "react";
import ApiServices from "../api/services";
import Spinner from "../components/Spinner";
import { images } from "../assets/styles/Images";
import { Link, useNavigate } from "react-router-dom";
import { useLoggedContext } from "../context/provider";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { dashboard, forgotPassword } from "../routes/routesDefined";

const Login = () => {
  let navigate = useNavigate();

  const { login, updateUser } = useLoggedContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
    var link = document.getElementById("password");
    link.blur();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = { email: email, password: values.password };

    ApiServices.auth
      .loginUser(payload)
      .then((response) => {
        if (response.data.code === 200) {
          localStorage.setItem("token", response.data.data.token);
          if (response.data.data.user.role === "super_admin" || response.data.data.user.role === "super_admin_user") {
            login(true);
            updateUser(response?.data?.data?.user);

            setLoading(false);

            navigate("/" + dashboard, { replace: false });
          } else {
            setLoading(false);
            setError("Invalid User");
            return;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className="relative flex items-center h-screen overflow-hidden bg-white">
      <div className="w-full h-screen px-6 bg-white sm:px-8 md:px-16">
        <div className="mx-auto flex h-[100%] w-full flex-col justify-center sm:w-[70%] xl:w-[35%] 2xl:w-[30%]">
         
            <img src={images.logo} alt="edufeel Social Logo" className="mx-auto mb-8 w-[15rem] lg:mx-0 2xl:w-[19.4rem]" />
          
          <div>
            <div className="mb-2 text-lg font-semibold leading-tight text-center text-grayColor500 lg:text-start 2xl:text-xl">
              Sign In to your account
            </div>
            <p className="mb-8 font-normal leading-normal text-center text-md text-grayColor300 lg:mb-10 lg:text-start lg:leading-snug 2xl:mb-12">
              Enter your email address and the password to log into the app.
            </p>
          </div>
          <form onSubmit={handleFormSubmit} className="w-full mx-auto" autoComplete="off">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`text-md text-grayColor500 mb-6 w-full rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:mb-8 lg:text-base`}
                id="email"
                placeholder="Email Address"
                autoComplete="off"
                required
                onkeypress="return event.charCode != 32"
              />
            </div>
            <div className="relative">
              <input
                type={values.showPassword ? "text" : "password"}
                onChange={handlePasswordChange("password")}
                className={`text-grayColor500 text-md mb-4 flex w-full items-center rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base`}
                id="password"
                placeholder="Password"
                value={values.password}
                autoComplete="off"
                required
              />
              <i
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                className="absolute right-5 top-[50%] translate-y-[-50%] cursor-pointer text-gray-50"
              >
                {" "}
                {values.showPassword ? <EyeIcon className="w-5 h-5 text-secondary" /> : <EyeSlashIcon className="w-5 h-5 text-secondary" />}
              </i>
            </div>
            {error?.length >= 1 && <span className="mb-8 text-red-500">{error}</span>}

            <div className="flex items-center justify-center mt-5 lg:mt-6 2xl:mt-10">
              <button className="w-full px-4 py-4 text-base font-medium text-white rounded-full shadow-md bg-secondary xl:text-lg">
                {loading ? <Spinner /> : "Sign In"}
              </button>
            </div>
            <div className="mt-12 text-center">
              {loading ? (
                <p className="text-base font-medium text-gray-500 underline forgot-password lg:text-base 2xl:text-lg">Forgot password?</p>
              ) : (
                <Link className="text-base font-medium underline forgot-password text-secondary lg:text-base 2xl:text-lg" to={"/" + forgotPassword}>
                  Forgot password?
                </Link>
              )}
            </div>
          </form>
        </div>
      </div>
      {/* <div className="hidden w-full h-screen bg-white sm:p-8 lg:block lg:w-1/2 lg:p-8 2xl:p-16">
        <img
          src={images.authImgSvg}
          className="object-cover w-full h-full ml-auto rounded-3xl "
          style={{ objectPosition: "50% 5%" }}
          alt="login img"
        />
      </div> */}
    </div>
  );
};

export default Login;
