import Dropdown from "./Dropdown";

import { images } from "../assets/styles/Images";
import { dashboard, settings } from "../routes/routesDefined";
import { Bars3Icon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

export default function AdminNavbar({ sidebarWidth, setShowBackDrop }) {
  const location = useLocation();
  const { id } = useParams();
  let navigate = useNavigate();

  const { isLoggedin, setShowSidebar, user } = useLoggedContext();

  let title = "";
  switch (location.pathname) {
    case "/dashboard":
      title = "Dashboard";
      break;
    case "/settings":
      title = "Settings";
      break;
    case "/support-tickets":
      title = "Support Tickets";
      break;
    case "/users":
      title = "Users";
      break;
    case "/payments":
      title = "Payments";
      break;
    case "/subscriptions-plans":
      title = "Subscriptions Plans";
      break;
    case `/settings/${id}`:
      title = "Settings Detail";
      break;
    default:
      title = "";
  }

  return (
    <>
      <nav
        className={
          "z-[10] flex h-[10vh] bg-white py-4 md:shadow-card lg:pr-16 2xl:py-6 3xl:h-[8vh] " +
          (" md:ml-[" + sidebarWidth + "px] ") +
          (isLoggedin ? "fixed top-0 w-full" : "")
        }
      >
        <div className="container relative mx-auto flex max-w-full items-center justify-between">
          <div className=" top-[-5px] md:hidden"></div>

          <div className="ml-5 flex w-full items-center justify-center md:justify-start">
            <div className="lg:hidden">
              <button
                onClick={() => {
                  setShowSidebar((prev) => (prev === "left-0" ? "-left-64" : "left-0"));
                  setShowBackDrop((prev) => !prev);
                }}
              >
                <Bars3Icon className="h-7 w-7 cursor-pointer" />
              </button>
            </div>
            <div className="navbar-brand cursor-pointer" style={{ width: sidebarWidth + "px" }} onClick={() => navigate(dashboard)}>
              <img src={images.logo} alt="Work well logo" className=" mx-auto w-[10rem] md:mx-0  md:w-[12rem] md:pl-[3rem] 2xl:w-[14rem]" />
            </div>
            <div className={`page-title hidden px-5 text-xl font-medium leading-normal text-secondary-color md:block lg:px-6 2xl:px-10 3xl:text-2xl`}>
              {title === "Settings Detail" ? (
                <div className="flex items-center gap-x-2 text-xl font-medium 3xl:text-2xl">
                  <Link className="text-gray-500 underline-offset-8 hover:underline" to={settings}>
                    Settings
                  </Link>
                  <ChevronRightIcon className="h-4 w-4 text-secondary-color" />
                  <h2>Settings Detail</h2>
                </div>
              ) : (
                title
              )}
            </div>
          </div>

          <div className="flex w-full items-center justify-end pr-4 lg:pr-0">
            <h2 className="mr-4 hidden text-lg font-medium leading-normal text-secondary-color md:block 3xl:text-xl">
              Hi, {user?.employee?.length > 0 ? user?.employee[0]?.name : "Admin"}
            </h2>
            <Dropdown />
          </div>
        </div>
      </nav>
    </>
  );
}
