import { Fragment } from "react";
import { images } from "../assets/styles/Images";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

export default function ReceiptModal({ data, isOpen, setIsOpen }) {
  // console.log("data", data);

  // Close Modal
  function closeModal() {
    setIsOpen(false);
  }

  // PDF styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#FFFFFF",
      padding: 20,
    },
    section: {
      margin: 20,
      padding: 20,
      flexGrow: 1,
    },
    section1: {
      margin: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    logo: {
      marginBottom: 10,
      width: 150,
      height: 40,
    },
  });

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-md p-8 space-y-12 overflow-hidden transition-all bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="flex items-center justify-between text-lg font-semibold leading-7 font-inter text-secondary">
                    Receipt
                    <XMarkIcon onClick={closeModal} className="w-6 h-6 cursor-pointer text-primary-color-100" />
                  </Dialog.Title>

                  <div className="mx-auto w-36">
                    <img src={images.logo} alt="receipt" className="object-contain w-full h-full" />
                  </div>

                  <div className="px-8 space-y-3">
                    <div className="flex items-center justify-between">
                      <h2 className="text-base font-semibold">Plan Name : </h2>
                      <p className="text-sm text-primary-color-100">{data?.plan_name}</p>
                    </div>

                    <div className="flex items-center justify-between">
                      <h2 className="text-base font-semibold">Plan Type : </h2>
                      <p className="text-sm text-primary-color-100">{data?.plan_type}</p>
                    </div>

                    <div className="flex items-center justify-between">
                      <h2 className="text-base font-semibold">Price : </h2>
                      <p className="text-sm text-primary-color-100">£{data?.price}</p>
                    </div>

                    <div className="flex items-center justify-between">
                      <h2 className="text-base font-semibold">Transaction : </h2>
                      <p className="text-sm text-primary-color-100">{data?.transaction_id}</p>
                    </div>
                  </div>

                  <div className="flex px-8 mt-12 gap-x-6">
                    <PDFDownloadLink
                      document={
                        <Document>
                          <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                              <Image src="https://i.ibb.co/QchN8Cj/Edu-Feel-Logo-01-1.png" style={styles.logo} />
                              <View style={styles.section1}>
                                <Text>Plan Name:</Text> <Text>{data?.plan_name}</Text>
                              </View>
                              <View style={styles.section1}>
                                <Text>Plan Type:</Text> <Text> {data?.plan_type}</Text>
                              </View>
                              <View style={styles.section1}>
                                <Text>Price: </Text> <Text>£{data?.price}</Text>
                              </View>
                              <View style={styles.section1}>
                                <Text>Transaction ID: </Text> <Text>{data?.transaction_id}</Text>
                              </View>
                            </View>
                          </Page>
                        </Document>
                      }
                      fileName="receipt.pdf"
                      className={`inline-flex w-full justify-center rounded-full border border-secondary bg-secondary px-4 py-2.5 text-base font-medium text-white transition-all duration-300 hover:opacity-75 focus:outline-none`}
                    >
                      {({ blob, url, loading, error }) => (loading ? "Loading..." : "Download")}
                    </PDFDownloadLink>

                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-full border border-secondary bg-transparent px-4 py-2.5 text-base font-medium text-secondary transition-all duration-300 hover:bg-secondary hover:text-white focus:outline-none`}
                      onClick={closeModal}
                    >
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
