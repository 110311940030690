import React, { useState } from "react";
import ApiServices from "../api/services";
import Spinner from "../components/Spinner";
import { login } from "../routes/routesDefined";
import { images } from "../assets/styles/Images";
import { Link, useNavigate } from "react-router-dom";
import { useLoggedContext } from "../context/provider";

const ForgotPassword = () => {
  let navigate = useNavigate();

  const { setBtnLoading, openSuccessModal } = useLoggedContext();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = { email: email };

    ApiServices.auth
      .forgotPassword(payload)
      .then((response) => {
        if (response.data.code === 200) {
          setLoading(false);
          openSuccessModal({
            title: "Success!",
            message: response.data.message,
            onPress: (close) => {
              close();
              navigate("/" + login, { replace: false });
            },
          });
          setEmail("");
        } else {
          setLoading(false);
          setError("Invalid User");
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const handleForgetInput = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  return (
    <>
      <div className="relative flex  h-screen overflow-hidden bg-white">
        <div className="w-full h-screen px-6 bg-white sm:px-8 md:px-16 lg:w-1/2">
          <div className="mx-auto flex h-full w-full flex-col justify-center sm:w-[70% 2xl:w-[60%]">
            <img src={images.logo} alt="edufeel Social Logo" className="mx-auto mb-8 w-[15rem] lg:mx-0 2xl:w-[19.4rem]" />

            <div>
              <div className="mb-2 text-lg font-semibold leading-tight text-center text-grayColor500 lg:text-start 2xl:text-xl">
                Forgot your password?
              </div>
              <p className="mb-8 font-normal leading-normal text-center text-md text-grayColor300 lg:mb-10 lg:text-start lg:leading-snug 2xl:mb-12">
                Enter your email below and receive a password reset link.
              </p>
            </div>
            <form onSubmit={handleFormSubmit} className="w-full mx-auto">
              <div>
                <input
                  value={email}
                  onChange={handleForgetInput}
                  type="email"
                  className={`text-md text-grayColor500 mb-3 w-full rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base 2xl:leading-loose`}
                  id="email"
                  placeholder="Email Address"
                  required
                  autoComplete="off"
                />

                {error.length >= 1 && <span className="pl-2 mb-8 text-red-500">{error}</span>}
              </div>
              <div className="text-right">
                {loading ? (
                  <p className="text-base font-medium text-gray-500 underline forgot-password lg:text-base 2xl:text-lg">Back to Login?</p>
                ) : (
                  <Link className="font-medium underline forgot-password text-grayColor500" to={"/" + login}>
                    Back to Login?
                  </Link>
                )}
              </div>

              <div className="flex items-center justify-center mt-5 lg:mt-6 2xl:mt-10">
                <button
                  className={`w-full rounded-full bg-secondary py-4 px-4 text-base font-medium leading-normal text-white shadow-md xl:text-lg 2xl:leading-loose `}
                >
                  {loading ? <Spinner /> : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="hidden w-full h-screen bg-white sm:p-8 lg:block lg:w-1/2 lg:p-8 2xl:p-16">
          <img src={images.authImgSvg} className="object-cover w-full h-full ml-auto rounded-3xl" style={{ objectPosition: "50% 5%" }} alt="member" />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
